.course-banner {
    height: 17rem;
    width: 100%;
    background: #7F56D9;
    position: relative;
}

.course-sidebar {
    display: flex;
    justify-content: center;
}

.course-landing {
    padding: 1rem;
}

.start-now {
    border-radius: 1.5rem;
    background: var(--Primary, #FF782D);
}

.cancel-continue {
    cursor: pointer
}

.course-default {
    width: 24.5rem;
    height: 10rem;
}

.course-info-details {
    margin: 0rem 2rem;
}

.course-info-section {
    width: 100% !important;
    min-height: 20rem;
}

.course-tab-section .MuiTabs-flexContainer {
    justify-content: space-evenly !important;
}



.similar-courses {
    position: relative;
    top: 7.5rem;
}

.slot-select {
    border: 1px solid #EFEFEF;
    position: relative;
}

.slot-radio {
    position: absolute;
    left: 1rem;
    top: 1rem;
}


.selected-day {
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    line-height: 20.57px;
    text-align: left;
    opacity: 75%
}

.selected-time {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #59585F;
}


.selected-slots {
    border: 1px solid #9C9C9C;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    padding: 3px;
    margin: 0.5rem 0rem
}

.add-slot {
    background: #FFFFFF!important;
    border: 1px solid #9C9C9C!important;
    font-family: Inter!important;
font-size: 16px!important;
font-weight: 400!important;
line-height: 24px;
letter-spacing: 0.15000000596046448px;
text-align: left;
color: #000000DE!important;
padding: 0.5rem!important;
text-transform: lowercase!important;
border-radius: 8px!important;
width: 125px!important

}

.plus-icon {
    margin: 0rem 0.5rem 0rem 0rem
}
.more-slots {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 1rem 0rem;
}

.recurring {
    margin: 0.75rem 0.5rem;
    display:flex;
    align-items: center;
}

.align-center {
    align-items: center;
    justify-content: center;
}

.payment-button {
    width: 50%
}

.no-classes {
    width: 3rem;
    height: 2.25rem;
    border: 1px solid #9C9C9C;
    border-radius: 8px;
    margin: 0rem 0.75rem;
}

.demo-class-button {
    background-color: #28a745 !important;
    margin: 0rem 0rem 0rem 1rem !important;
    width: 9rem !important;
    border-radius: 3rem !important;
    padding: 0.5rem !important;
}

.demo-class {
    color: white!important
}

.validity {
    color: #7B2C9F;

}


.recurring-text {
    margin: 0rem 0.5rem;
}

.available-time {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: #59585F;

}

.week-day {
    font-family: DM Sans !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px;
    text-align: center;
    color: #9D9CA3 !important;


}

.available-number {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.limited {
    color: #FAB437;
}

.available {
    color: #06D327;
}

.course-slots table {
    table-layout: fixed;
    width: 100%
}

.course-slots th,
.course-slots td {
    width: 14.2857%
        /* 100% / 7 days of the week */
}

.course-slots td {
    height: 100px;
}

.batch-size {
    background: #2580D5;
    padding: 3px 8px 3px 8px;
    border-radius: 0px 0px 0px 8px;
    position: absolute;
    right: 0rem;
    top: 0rem;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    text-align: center;
    color: #FFFFFF;
    width: 90px
}

.course-slots {
    margin: 7.5rem 4rem 2rem 4rem;
}

.footer-slot {
    background: white;
    border-top: 1px solid #DADADA;
    box-shadow: 0px -6px 40px 0px #00000021;
    height: 4.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
    position: fixed;
    bottom: 0rem;
}

.select-slot-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
}

.available-slots-title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.89px;
    text-align: left;
    width: 200px;
    position: relative;
    bottom: 1.5rem;
}

.begin{
    margin: 0rem 1rem 0rem 0rem;
}

.begin-journey {
    font-family: Urbanist;
font-size: 22px;
font-weight: 600;
line-height: 26.4px;
text-align: left;

}

.slot-continue {
    background: #8931B1 !important;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46000000834465027px;
    text-align: left;
    border-radius: 8px;
    height: 42px;
    width: 172px;
    color: #FFFFFF !important;
    margin: 0rem 2rem !important;
}


.final-count {
    font-size: 18px !important;
    margin: 0rem 0rem 0.75rem 0rem;
}

input[type="checkbox"]:checked {
    accent-color: #7f56d9 !important;
}


input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.similar-text {
    text-align: left;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.02em;
    opacity: 0.5;
}

.similar-course-image {
    width: 12rem;
    height: 8.5rem;
}

.similar-course-container {
    height: 15.5rem;
    width: 12rem;
    margin: 0rem 1rem 0rem 0rem;
}

.similar-list {
    justify-content: flex-start;
}

.course-details-image {
    width: 25rem;
    height: 16.5rem;
    border-radius: 1.25rem 1.25rem 0rem 0rem;
    position: relative;
    left: 0.5rem;
}

.course-renew {
    width: 25rem;
    border-radius: 0rem 0rem 1.25rem 1.25rem;
    border: 1px solid var(--Light_grey, #EAEAEA);
    background: var(--White, #FFF);
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    display: block;
}

.whatsapp-notification {
    color: gray;
    font-size: 11px;
}



@media screen and (max-width: 1024px) {
    .course-banner {
        margin: 0rem 0rem 0rem 0rem;
    } 
    
    .col-width {
        width:100%;
    }

    .course-landing{
        height:10rem;
    }

    .course-creds {
        display: flex;
        flex-direction: column;
    }

    .course-renew {
        width:16.25rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: relative;
        left: 0.25rem;

    }

    .demo-class-button {
        font-size: 12px!important;
    }

    .course-sidebar {
        margin: 0rem 0rem 6rem 0rem;
    }

    .renew-now{
        font-size: 12px!important;
    }

    .course-details-image {
        width: 16rem;
        height: 10rem;
        border-radius: 1.25rem 1.25rem 0rem 0rem;
    }

    .course-detail-section{
        margin: 12rem 0rem 0rem 0rem;
    }

    .course-tab-section {
        margin: 0rem 2rem 0rem 0rem;
    }

    .similar-courses {
        top:4rem;
    }

    .similar-text {
        text-align: center;
    }

    .batch-size {
        width: 4.5rem;
    }

    .slot-radio{
       left: 0.5rem;
       top: 1.5rem;
    }

    .available-time {
        line-height:15px;
        margin: 2rem 0rem 0.5rem 0rem
    }

    .available-number {
        font-size:10px;
    }

    .select-slot-text {
        font-size: 16px;
        margin: 0.5rem 1rem 0.5rem 0rem;
    }
    
    .footer-slot {
        flex-direction: column;
    }

    .course-info-details {
        display: flex;
        justify-content: center;
    }

    
}