.overview-container {
    width: 100%;
    ;
    height: 100%;
}


.course-buttons {
    margin: 1rem 0.5rem 0.5rem 0.5rem !important;
}

.tutor-student-count {
    padding: 0.625rem 1rem 0.625rem 1rem;
    border-radius: 3rem;
    background: #F2EFF8;
    width: 10.2rem;
    text-align: center;
}

.student-count-text {
    font-family: Urbanist;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    color: #7F56D9;
}

.course-title-edit .MuiOutlinedInput-root {
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 120%;
}

.edit-course-section {
    width: 25rem;
}

.count-pill {
    display: flex;
    justify-content: space-between;
    margin: 0rem 0rem 1rem;
}

.select-students-notes {
    margin: 1rem 0rem;
}

.send-notes-title {
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
    color: #3E435DCC;
}

.send-notes-spacing {
    margin: 0rem 1rem 0rem 0rem;
}

.check-student-classes {
    cursor: pointer;
}

.send-notes-button {
    border: 1px solid #8931B1 !important;
    width: 7.5rem;
    color: white !important;
    background-color: #8931B1 !important;
}

.list-class {
    margin: 1rem 0rem;
}

.student-detail {
    height: 8rem;
    width: 8rem;
    border-radius: 4rem;
}

.completed-class {
    color: white;
    background: #2DDB7D;
    border-radius: 10px;
}

.selected-student-details {
    margin: 1rem
}

.selected-student-name {
    font-family: Urbanist;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
    color: #3E435DCC;

}

.attended-students {
    margin: 1.5rem;
}

.student-attended-list {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    margin: 0.5rem 0rem

}

.attended-student {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    opacity: 0.6;
    padding: 0.15rem 0rem;
}

.sent-notes {
    margin: 0.5rem 0rem;
}

.selected-student-validity {
    width: 167px;
    height: 16px;
    gap: 0px;
    opacity: 0.4px;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #000000E5;
}

.notes-list {
    max-height: 14rem;
    overflow-y: scroll;
}

.notes-container {
    position: relative;
}

.notes-images {
    display: flex;
    flex-wrap: wrap
}

.attach-notes {
    margin: 0.5rem 0rem;
    color: #7f56d9 !important;
    font-size: 12px;

}

.upload-notes {
    cursor: pointer;
    height: 6.5rem;
    width: 6.5rem;
    margin: 0.5rem 0rem;
    border-radius: 4px;
}

#outlined-multiline-static-label {
    color: #7f56d9 !important;
    font-family: Inter !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.remove-note {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    cursor: pointer;
}

.notes-spacing {
    margin: 1rem 0rem 0rem 0rem
}

@media(max-width: 1024px){
    .course-tutor-section{
        margin: 0rem;
    }
}