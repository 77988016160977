.create-class {
    background: #7F56D9;
    border-color: #7F56D9;
    width: 15rem;
    height: 3rem;
    padding: 12px 28px 12px 20px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
}

.create-class:hover {
    background: #7F56D9;
    border-color: #7F56D9;
}

.align-text-center {
    text-align: center;
}

.create-new-course {
    width: 10rem!important;
    margin: 0rem 1rem 0rem 3rem!important;
}


@media (max-width: 1024px) {
    .create-class {
        font-size: 12px;
        
    }
    .course-tabs .row {
        flex-direction: row;
    }

    .tutor-history-frame {
        flex-direction: row!important;
    }

    .validity-date {
        right:1rem;
    }


}

@media (max-width: 480px) {
    .create-class {
        font-size: 11px;
    }
}