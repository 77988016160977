.padding-remove {
    padding: 0rem;
}

.course-banner {
    height: 17rem;
    width: 100%;
    background: #7F56D9;
    position: relative;
    padding: 1.5rem;
}

.course-details-banner {
    margin: 0rem 0rem 0rem 3rem;
}

.course-title-details {
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 120%;
}

.arrow-icon-details {
    position: absolute;
    left: 1.5rem;
    top: 2rem;
}

.by-tutor {
    margin: 1rem 0rem;
    color: rgba(255, 255, 255, 0.90);
}

.course-stats {
    margin: 0rem 1.5rem 0rem 0rem;
    color: rgba(255, 255, 255, 0.70);
    font-size: 0.875rem;
}

.icon-course-stats {
    margin: 0rem 0.5rem 0rem 0rem;
}


.validity {
    font-family: Inter;
}

.renew-now {
    margin: 0rem 0rem 0rem 1rem !important;
    width: 9rem !important;
    border-radius: 3rem !important;
    background-color: #ff782d !important;
    padding: 0.5rem !important;
}

.start-now {
    background: #8931B1 !important;
}



.calendar-progress {
    border-radius: 0.5rem;
    background: var(--White, #FFF);
    display: inline-flex;
    margin: 1.5rem;
    width:90%;
}

.progress-section {
    margin: 0rem 0rem 0rem 2.5rem;
    display: flex;
    justify-content: space-between;
}

.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-row-custom {
    display: flex;
    justify-content: center;
}

.align-center {
    text-align: center;
}

.class-count-course {
    border-radius: 6.25rem;
    border: 1px solid #F3F3F3;
    background: linear-gradient(90deg, #E1F3FF 74.51%, rgba(241, 247, 251, 0.00) 75%);
    padding-right: 0.375rem;
    font-family: Inter;
    font-size: 0.75rem;
}

.slot-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0.75rem 1rem 0rem
}

.green-icon {
    padding: 0.75rem;
}

.slot-name {
    font-family: Urbanist;
    font-size: 1.125rem;
    font-weight: 600;
}

.slot-timing {
    font-family: Inter;
    font-size: 0.8125rem;
    opacity: 0.4;
}

.slot-note-divider {
    margin: auto;
    width: 90%;
    display: flex;
}

.notes-lesson {
    color: rgba(0, 0, 0, 0.60);
    font-family: Urbanist;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.5rem;
    display: flex;
}

.course-lesson {
    border-radius: 0.625rem;
    left: 0.25rem;
    position: relative;
    background: var(--White, #FFF);
    display: flex;
    padding: 1.125rem 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width:90%
}

.lesson-text {
    color: var(--Color-dark-grey, var(--Dark_gray, #555));
    font-family: Inter;
    font-size: 0.75rem;
}

.lesson-image {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0.5rem 0rem;
    cursor: pointer;
}

.course-information {
    position: relative;
    top: 6rem;
    display: flex;
    justify-content: center;
    right: 1.5rem;
}

.course-tabs-details {
    width: 26rem
}

.course-tabs-details .MuiTabs-flexContainer {
    justify-content: space-between;
}

.course-section {
    width: 100%;
    border-radius: 0.75rem;
    background: var(--White, #FFF);
    padding: 1.88rem;
}

.flex {
    display: flex;
}

.tutor-image-course {
    width: 11.25rem;
    height: 11.25rem;
    border-radius: 1.25rem
}

.tutor-section-course {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0rem 1.5rem;
    align-items: center;
    min-width: 125px
}

.student-count {
    margin: 0rem 0.5rem;
    font-family: Inter;
    color: #555555;
}

.about-tutor {
    margin: 1.5rem 0rem;
    font-family: Inter;
    color: #555555;
}

.current-rating {
    padding: 0rem 0rem 1.25rem 0rem;
}

.avg-rating {
    margin: 0.25rem 0.75rem;
    color: var(--Black, #000);
    font-family: Exo;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 120%;
}

.base-rating {
    color: var(--Dark_gray, #555);
    padding: 0rem 0.5rem;
    font-family: Jost;
}

.add-review {
    border-top: 1px solid var(--Light_grey, #EAEAEA);
    padding: 1.25rem 0rem;
}

.review-image {
    border-radius: 2.6875rem;
    width: 3.75rem;
    height: 3.75rem;
    margin: 0rem 1.25rem 0rem 0rem;
}

.review-field {
    width: 100%
}

.review-section {
    position: relative;
    margin: 0rem 0rem 1rem 0rem;
}

.submit-review {
    position: absolute !important;
    right: 0rem;
}

.flex-align {
    display: flex;
    justify-content: space-between;
    width:15rem;
}

.review-name {
    font-family: Inter;
    font-weight: 600;
}

.review-date {
    color: var(--Dark_gray, #555);
    font-family: Inter;
}

.calendar-progress .Mui-selected {
    background-color: #7F56D9 !important;
    color: white !important;
}

@media (max-width: 1024px) {
    .course-details-sidebar{
        display: none
    }

    .course-detail-dashboard {
        margin: 6rem 0rem 0rem 0rem;
    }

    .progress-section {
        justify-content: space-around;
    }

    .course-information {
        top: 3rem;
        right: 0rem;
    }

    .slot-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }

    .width-50 {
        width:50%
    }

    .mobile {
        width: 100%
    }
}


