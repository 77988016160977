.header {
    display: flex;
    justify-content: space-between;
    padding: 0rem 4rem;
    align-items: center;
    height: 5rem;
    background-color: #8931B1;
    width: 100%
}

.main-navbar {
    background: #FFFFFF;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
}

.navbar-heading {
    display: flex;
    align-items: center;
    gap: 2.75rem;
}

.link-heading {
    color: var(--general-light, #FFF);
    font-family: 'Urbanist';
    font-weight: 700;
    text-decoration: none;
}

.main-link-heading {
    color: #7F56D9 !important;
}

.login-main {
    margin: 0rem 3rem 0rem 0rem;
}

.login-heading {
    line-height: normal;
    padding: 1.125rem 3rem;
    border-radius: 0.5rem;
    background: var(--general-light, #FFF);
    color: #7F56D9 !important;
}

.hamburger-icon {
 display: none;
 position: relative;
}

.hamburger-icon div {
    background-color: #FFF;
    transition: all 0.3s ease-in-out;
    position: absolute;
}

/* Hamburger animation: When clicked, change into an 'X' */
.hamburger-icon.open div:nth-child(1) {
    transform: rotate(45deg);
}

.hamburger-icon.open div:nth-child(2) {
    opacity: 0;
}

.hamburger-icon.open div:nth-child(3) {
    transform: rotate(-45deg);
}

.drawer {
    display: none
}

/* Drawer style */


  

.modal-dialog {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    padding: 1rem;
    border-radius: 1.125rem;
    background: #FFF;
    width: 35rem;
}

.modal-content {
    border: none !important
}

.modal-header{
    border: none!important
}

.auth-main-logo {
    position: relative;
    top: 1rem;
}

.auth-sub-heading {
    color: #757575;
    font-family: Montserrat;
    font-weight: 600;
    margin: 0.5rem 0rem 0rem 0rem;
}

.auth-main-heading {
    color: #424242;
    font-family: Montserrat;
    font-size: 1.5625rem;
    font-weight: 700;
    letter-spacing: -0.03125rem;
    margin: 0rem 0rem 1.5rem 0rem;
}

.social-logins {
    display: flex;
    justify-content: space-between;
}

.google-login {
    border-radius: 0.75rem;
    background: #E3F3FB !important;
    border-color: #E3F3FB !important;
    width: 13rem;
    height: 3.5rem;
}

.continue-with-google {
    display: flex;
    column-gap: 10px;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    color: #424242;
    font-family: Montserrat;
    font-size: 0.8125rem;
    font-weight: 600;
}

.auth-footer {
    margin: 0.75rem;
    display: flex;
    justify-content: space-evenly;
}

.forgot-password {
    font-family: Montserrat;
    font-size: 0.875rem;
    font-weight: 700;
}

.login-signup {
    color: #616161;
    font-family: Montserrat;
    font-size: 0.875rem;
}

.auth-text {
    text-decoration: none;
    color: #616161;
    cursor: pointer;
}

.auth-bold {
    color: #535353;
    font-weight: 800;
}

@media (max-width: 1024px) {

    .navbar-heading {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 5rem;
      right: 0;
      background-color: #8931B1;
      width: 100%;
      height: auto;
      align-items: flex-start;
      padding: 1rem;
      transition: transform 0.3s ease-in-out;
    }
  
    .navbar-heading.open {
      display: flex;
    }
  
    .hamburger-icon {
      display: block;
      position: relative;
      bottom: 0.5rem;

    }
  
    .header {
      padding: 0rem 1rem;
    }
  
    .link-heading {
      padding: 1rem;
      width: 100%;
      text-align: left;
    }
  
    .login-heading {
      width: 100%;
    }

    .drawer {
        position: fixed;
        top: 0;
        right: -100%; /* Hide initially */
        width: 90%;
        height: 100%;
        background-color:white ;
        transition: right 0.4s ease;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
    }
    
    .drawer.open {
        right: 0; /* Slide in */
    }
    
    /* Styling inside the drawer */
    .drawer .link-heading {
        color: #7F56D9;
        text-decoration: none;
        font-size: 1.25rem;
        font-family: 'Urbanist', sans-serif;
        /* border: 1px solid #8931B1 ; */
        /* padding: 0.75rem 1.5rem; 
        border-radius: 8px;
        margin: 1rem 0; */
    }

    .login-heading {
        line-height: 1.75rem;
    }
    
    .drawer .close-drawer {
        align-self: flex-end;
        background: none;
        border: none;
        font-size: 1.75rem;
        cursor: pointer;
        margin-bottom: 1rem;
    }
    
    .drawer .link-heading:hover {
        color: #8931B1; 
    }
    .modal-dialog {
        width: 90%; /* Reduce the width for smaller screens */
    }

    .auth-main-heading {
        font-size: 1.25rem; /* Decrease the font size */
    }

    .social-logins {
        flex-direction: column;
        gap: 1rem;
    }

     .google-login {
        width: 100%;
    } 

    .auth-footer {
        flex-direction: column;
        gap: 1rem; /* Stack footer items vertically */
    }

    .forgot-password {
        text-align: center;
    }

    .login-signup {
        text-align: center;
    }
  }