.course-tabs {
    margin: 1.5rem;
}

.course-tabs .Mui-selected {
    color: #7F56D9 !important;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: #7F56D9 !important
}

.course-tab-display {
    margin: 1rem 0rem;
}

.course-history {
    border-bottom: 0.5px solid #E0E0E0;
    background: var(--White, #FFF);
    position: relative;
}

.course-bar {
    display: flex;
    padding: 0.5rem;
    cursor: pointer;
}

.course-naming {
    margin: 1rem;
}

.course-name-bar {
    font-family: Urbanist;
    font-weight: 600;
    line-height: normal;
    margin: 0.25rem 0rem;
}

.course-progress {
    display: flex;
    align-items: center;
}

.progress-heading {
    margin: 0rem 0.5rem 0rem 0rem;
    font-family: Inter;
    font-size: 0.75rem;
    line-height: normal;
}

progress[value] {
    height: 0.25rem;
    color: #186DBF;
}

.validity-date {
    position: absolute;
    top: 1.5rem;
    right: 7.5rem;
    color: #00000052;
    font-family: Inter;
    font-size: 0.75rem;
    line-height: 23px;
}

.class-count {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1.5rem;
    right: 3rem;
    border-radius: 6.25rem;
    border: 1px solid #F3F3F3;
    background: linear-gradient(90deg, #E1F3FF 74.51%, rgba(241, 247, 251, 0.00) 75%);
    padding-right: 0.375rem;
    font-size: 0.75rem;
    font-weight: 300;
}

@media (max-width: 480px) {
    .course-name-bar {
        font-size: 0.85rem;
    }
    
    .class-count {
        right: 1rem;  
        top:3rem;
    }
    .course-naming {
        width: 50%;
    }
}