.profile-name {
    padding: 1rem;
}

.sidebar-icon {
    position: relative;
    top: 0.2rem;
    padding: 0rem 0.5rem;
}

.flex-display {
    display: flex;
}

.student-sidebar {
    width: 100%;
    left: 0rem;
    height: 100%;
    background-color: white;
    text-align: center;
}

.active {
    border-radius: 0.5rem;
    background: #7F56D9 !important;
}

.sidebar-content {
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem 1.5rem 2.25rem;
    height: 3rem;
    margin: 0rem 0rem 1rem 0rem;
}

.nav_link :hover {
    border-radius: 0.5rem;
    background: rgba(167, 176, 188, 0.12);
}

.sidebar-icons {
    position: relative;
    margin: 0rem 0.5rem;
    bottom: 0.2rem;
}

.active-color {
    color: white !important;
}

.sidebar-main {
    margin: 1.5rem 2.5rem;
}

.sidebar-logo {
    width: 11rem;
    padding: 1.5rem 0rem;
}

.sidebar-heading {
    color: #A7B0BC;
    /* font-family: Montserrat; */
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.nav_link {
    color: white;
    text-decoration: none;
}

.profile-sidebar-image {
    margin: 0.75rem;
    width: 5rem;
    height: 5rem;
    border-radius: 3rem;
}

.cursor-pointer {
    cursor: pointer
}

@media (max-width: 1024px) {

    .sidebar-main {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0rem;
        padding: 0rem 0rem 1rem 0rem; 
    }

    .mobile {
        width: 100%
    }

    .sidebar-content {
    justify-content: center;
    height: 2rem;
    margin: 0rem;
    padding: 1.5rem;
    }

    .sidebar-heading {
        font-size: 0.95rem
    }
}