.pay-button {
    margin: 15px 5px;
    background: #8931B1;
    border-color: #8931B1;
    width: 100%;
    height: 50px !important
}

.pay-button:hover {
    background: #8931B1;
    border-color: #8931B1;
}    

.cancel-pay-button {
    width: 50%;
    border: 1px solid #0000004D!important;
    border-radius: 8px;
    height: 50px;
    align-items: center !important;
    justify-content: center;
    display: flex !important;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: black !important
}