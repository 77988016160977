.Appointment-appointment,
.SaveButton-button {
    background-color: #7F56D9 !important
}

.Content-lens,
.Cell-highlightedText,
.OpenButton-textButton,
.TodayButton-button,
.NavigationButton-button {
    color: #7F56D9 !important
}

.TodayButton-button {
    border: 1px solid #7F56D9 !important;
}