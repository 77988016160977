.auth-card {
  margin: 0.25rem;
}

.or-section {
  display: flex;
  align-items: center;
  margin: 1.5rem 0rem;
}

.line {
  width: 100%;
}

.or {
  position: absolute;
  padding: 0.1rem 0.6rem 0.5rem 0.5rem;
  background: rgb(255, 255, 255);
  left: 50%;
  transform: translateX(-50%);
  color: #616161;
  font-family: Montserrat;
  font-size: 0.875rem;
}

.fields {
  margin: 1rem 0rem;
  position: relative;
  border-radius: 0.5rem;
  border: 0.795px solid #CBD1D8;
}

.auth-fields {
  border-radius: 0.5rem;
  border: 0.795px solid #CBD1D8;
  background: #FAFAFC;
  height: 3rem;
  padding: 0.75rem 1rem;
  font-family: Montserrat;
}

.password-eye {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.submit {
  border-radius: 0.75rem !important;
  background: #7F56D9 !important;
  border-color: #7F56D9 !important;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  height: 3rem;
}

.react-tel-input {
  margin: 1rem 0rem;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 3rem !important;
  border: 0.795px solid #CBD1D8 !important;
  background: #FAFAFC !important;
  font-family: Montserrat !important;
  margin: 1rem 0rem;
}

.verify-otp {
  color: #424242;
  font-family: Montserrat;
  font-size: 1.5625rem;
  font-weight: 700;
 
}

.otp-text {
  color: #757575;
  font-family: Montserrat;
  font-weight: 600;
}

.edit-phone{
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  margin: 1rem;
  display: inline-flex;
}

.otp-container {
  display: flex;
  justify-content: space-evenly;
}

.otp-login {
  margin: 1rem 0rem;
}

.resend-otp{
  text-align:right;
}