

.navbar-logo {
    padding: 1.5rem 2.5rem;
    width: 15rem;
}

.other-breadcrumb {
    font-family: Inter!important;
    font-size: 16px!important;
    font-weight: 400!important;
    line-height: 24px!important;
    text-align: left!important;
    color: #9D9D9D!important;
    text-decoration: none!important;
}

.selected-breadcrumb {
    font-weight: 600!important;
    color: #555555!important;
}

.breadcrumbs {
    padding: 1.5rem 2.5rem;
}