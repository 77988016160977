.tutor-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.tutor-cards {
    width: 18.75rem;
    height: 25rem;
    border-radius: 12px;
    margin: 1rem 0rem;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 4px 35px 0px #7966EA14;
}

.course-tag {
    top: 1rem;
    left: 1rem;
    padding: 0.5rem;
    min-width: 6rem;
    position: absolute;
    border-radius: 8px;
    background: var(--Black, #000000);
    color: white;
    font-family: Inter;
    font-size: 14px;
    text-align: center;
}

.tutor-image {
    width: 18.625rem;
    height: 15.625rem;
    border-radius: 0.75rem 0.75rem 0rem 0rem;
}

.tutor-name {
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 600;
    color: #06241B;
}

.tutor-details {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 0.75rem;
    opacity: 0.4;
}

.tutor-icons {
    margin: 0rem 1rem 0rem 0rem;
}

.student-icon {
    opacity: 0.3;
}

.view-more {
    text-align: right;
    margin: 0.5rem 0rem;
    font-family: Inter;
    font-size: 14px;
    font-weight: 1000;
    cursor: pointer;
}