html,
body {
    overflow-x: hidden;
}

body {
    position: relative
}


.w-100 {
    width: 100%
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
        visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}


.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.main-heading {
    position: absolute;
    top: 9rem;
    left: 4rem;
    width: 36rem;
}

.headline {
    color: var(--general-light, #FFF);
    font-family: Urbanist;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.main-keyword {
    color: #FC5252;
}

.headline-detail {
    margin: 1rem 0rem 2rem 0rem;
    color: var(--general-light, #FFF);
    font-family: Urbanist;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.headline-buttons {
    display: flex;
    align-items: center;
    gap: 2.5rem;
}

.get-started {
    display: flex;
    padding: 1.125rem 3.125rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    background: var(--general-red, #FC5252);
    height: 4rem;
}

.get-started-text {
    color: var(--general-light, #FFF);
    font-family: Urbanist;
    font-size: 1.25rem;
    font-weight: 500;
    text-decoration: none;
}

.watch-video {
    padding: 0rem 0.5rem;
}

.trusted-by {
    margin: 3rem 0rem 0rem 0rem;
    color: rgba(255, 255, 255, 0.80);
    font-family: Urbanist;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75rem;
}

.badges {
    display: flex;
    justify-content: space-between;
}

.trusted {
    margin: 0rem 0.5rem;
}

.explore {
    font-size: 2.125rem;
    font-weight: 800;
    line-height: 130%;
    display: flex;
    margin: 2.5rem;
    justify-content: center;
}

.explore:before {
    content: "Explore ";
    color: #7F56D9;
}

.items {
    display: flex;
    justify-content: space-evenly;
}

.categories {
    padding: 2rem 1.8125rem;
    gap: 0.5rem;
    border-radius: 1rem;
    height: 14rem;
    width: 15rem;
    transform: scaleY(1) translateY(0);
    transform-origin: top center;
    transition: transform 0.3s ease-in-out;
}

.category1 {
    background: #FBF3E8;
}

.category2 {
    background: #FEE;
}

.category3 {
    background: #F9E5F5;
}

.category4 {
    background: #F3F9FF;
}

.category5 {
    background: #F9FBEA;
}

.categories:hover {
    border-radius: 1rem;
    background: linear-gradient(180deg, #8931B1 0%, #EF77E2 100%);
    box-shadow: 0px 10px 30px 0px rgba(137, 49, 177, 0.20);
    cursor: pointer;
    transform: scaleY(1.1);
    color: white;
    height: 15rem;
    line-height: 1.15rem;
}

.category-image {
    width: 3.5rem;
    height: 3.5rem;
}

.category-card {
    padding: 0rem;
}

.category-type {
    font-family: Urbanist;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2.375rem;
}

.category-about {
    font-family: Raleway;
    font-size: 0.875rem;
    opacity: 0.7;
    font-weight: 500;
}

.learn-more {
    display: none;
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 1rem 0rem;
}

.categories:hover .learn-more {
    display: inline-block;
    /* or any display value suitable for your design */
}

.arrow-learn-more {
    padding: 0rem 0.5rem;
}

.tutors {
    margin: 5rem 0rem;
}

.tutor-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem;
}

.our-tutors {
    font-family: Urbanist;
    font-size: 2.125rem;
    font-weight: 800;
    line-height: 130%;
    margin: 2rem 0rem;
}

.tutor-description {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.7
}

.view-tutors {
    border-radius: 0.5rem;
    background: #7F56D9;
    box-shadow: 0px 10px 30px 0px rgba(56, 29, 219, 0.20);
    display: flex;
    padding: 1.125rem 2.125rem;
    align-items: center;
    gap: 0.75rem;
    margin: 2.5rem 0rem;
    color: var(--general-light, #FFF);
    font-family: Urbanist;
    font-weight: 700;
    text-decoration: none;
    width: 13.5rem;
}

.top-features {
    padding: 6.5rem 8.125rem;
    background: #DEFF80;
    height: 48rem;
}

.feature-body {
    display: flex;
    justify-content: space-around;
    margin: 0rem 0rem 1.5rem 0rem;
}

.feature-heading {
    color: #05380C;
    font-family: Urbanist;
    font-size: 2.9375rem;
    font-weight: 600;
    line-height: normal;
    width: 20rem;
}

.feature-text {
    color: #000;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.875rem;
    width: 28rem;
    margin: 0rem 10rem 0rem 3rem;
}

.get-started-feature {
    font-family: "Montserrat";
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4.375rem;
    background: #FFF;
    width: 10rem;
    height: 4rem;
    text-decoration: none;
    color: black;
}

.features-container {
    border: none;
    background-color: #DEFF80;
    margin: 1rem;
    width: 17.5rem;
}

.feature-image {
    height: 15rem;
    width: 17.5rem;
    border-radius: 1.25rem;
    background: #F9F8FE;
    margin: 0rem 0rem 1.5rem 0rem;
}

.feature-title {
    color: #191A15;
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-family: "Urbanist";
    font-weight: 600
}

.feature-about {
    color: #666;
    font-family: "Montserrat";
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.875rem;
    padding: 1rem 0rem;
}

.top-courses {
    height: 38.5rem;
    background: #F8F8FF;
    padding: 4rem;
}

.course-headline {
    color: #06241B;
    font-family: Urbanist;
    font-size: 2.125rem;
    font-weight: 800;
}

.popular-courses {
    color: #4A03A5;
}

.course-title {
    color: #7E7EAA;
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: 500;
}

.course-list {
    display: flex;
    justify-content: space-evenly
}

.courses {
    border-radius: 1.5rem;
    margin: 2rem;
    width: 16.5rem;
}

.course-link {
    text-decoration: none;
    color: white;
}

.course-image {
    height: 12rem;
    border-radius: 1.5rem 1.5rem 0rem 0rem;
}

.course-about {
    color: #06241B;
    font-family: Urbanist;
    font-weight: 600;
    padding: 0.5rem 0rem 0rem 0rem;
}

.course-tutor {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
}

.course-tutor-name {
    font-family: Urbanist;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0rem 1rem;
}

.course-name {
    color: #7E7EAA;
    font-family: Urbanist;
    font-size: 0.6875rem;
    font-weight: 500;
    padding: 0rem 2.5rem;
}

.benefits {
    padding: 6rem;
    background: #FFDD74;
}

.benefit-image {
    width: 100%;
}

.benefit-heading {
    width: 25rem;
    color: #191A15;
    font-family: Urbanist;
    font-size: 2.9375rem;
    font-weight: 600;
    line-height: normal;
    margin: 0rem 0rem 3rem 0rem;
}

.benefit-list {
    margin: 1rem;
}

.benefit-text {
    color: #191A15;
    margin: 1rem;
    font-family: Montserrat;
    font-weight: 500;
}

.testimonials {
    height: 27.5rem;
    border-radius: 0rem 0rem 0rem 9.875rem;
    background: #F1F1FF;
    padding: 3rem;
    margin: 0rem 0rem 12rem 0rem;
}

.testimonial-heading {
    color: #4A03A5;
    font-family: Urbanist;
    font-size: 2.625rem;
    font-weight: 700;
    line-height: 130%;
}

.testimonial-title {
    color: #7E7EAA;
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: 500;
}

.testimonial-container {
    display: flex;
    margin: 4.5rem 0rem 0rem 0rem;
}

.testimonial-card {
    padding: 1.875rem 2.0625rem;
    gap: 0.5rem;
    border-radius: 1.875rem;
    background: #FFF;
    border-color: white;
    box-shadow: 0px 4px 35px 0px rgba(121, 102, 234, 0.08);
    width: 25rem;
    height: 18rem;
    margin: auto;
}

.testimonial-review {
    display: flex;
}

.student-image {
    width: 4.0625rem;
    height: 4.0625rem;
    border-radius: 4.0625rem;
}

.student-name {
    color: #1D1D45;
    font-family: Urbanist;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 150%;
    padding: 0.5rem 2rem;
}

.student-review {
    color: #7E7EAA;
    font-family: Montserrat;
}

.footer-music {
    height: 36.25rem;
    background: linear-gradient(180deg, #8931B1 0%, #101 100%), #0C1013;
}

.footer-container {
    margin: auto;
    position: relative;
    left: 4rem;
}

.footer-company {
    color: var(--general-light, #FFF);
    font-family: Raleway;
    font-size: 0.75rem;
    opacity: 0.6;
    padding: 1rem 0rem;
}

.social-icons {
    width: 25%;
    display: flex;
    justify-content: space-between;
}

.contact {
    margin: 2rem 0rem;
}

.contact-us {
    color: var(--general-light, #FFF);
    font-family: Urbanist;
    font-size: 2.125rem;
    font-weight: 700;
}

.inquiry {
    color: var(--general-light, #FFF);
    font-family: Montserrat;
    font-size: 0.875rem;
    font-weight: 500;
    opacity: 0.7;
}

.contact-details {
    color: var(--general-light, #FFF);
    font-family: Montserrat;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 1rem 0rem;
    font-size: 14px;
}

.contact-icons {
    margin: 0rem 0.25rem;
}

.fill-form {
    position: relative;
    bottom: 5rem;
    left: 3rem;
    display: inline-flex;
    padding: 2.875rem 4rem;
    flex-direction: column;
    justify-content: center;
    border-radius: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.22);
    background: rgba(255, 255, 255, 0.40);
    box-shadow: 0px 4px 64px 0px rgba(121, 102, 234, 0.18);
    backdrop-filter: blur(32px);
    width: 36rem;
    height: 32rem;
}

.fill-details {
    padding: 1.875rem 2.0625rem;
    border-radius: 1.125rem;
    background: #FFF;
    box-shadow: 0px 4px 35px 0px rgba(121, 102, 234, 0.08);
    height: 27rem;
    width: 28rem;
}

.form-details {
    display: flex;
    flex-direction: column;
    gap: 0.52rem;
}

.footer-form {
    color: var(--general-dark, #222);
    font-family: Urbanist;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2.375rem;
    margin: 1rem 0rem;
}

.footer-label {
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1.125rem;
    opacity: 0.7;
}

.footer-input {
    border: none;
    border-bottom: 1px solid #E0E0E1;
    margin-bottom: 10px;
}

.message-label {
    color: #8931B1
}

.message-input {
    border-bottom: 1px solid #8931B1;
}

.submit-message {
    border-radius: 0.75rem;
    background: #7F56D9;
    border-color: #7F56D9;
    display: flex;
    height: 3.5rem;
    margin: 1rem 0rem;
    justify-content: center;
    align-items: center;
}

.submit-message:hover {
    background: #7F56D9;
    border-color: #7F56D9;
}

.footer-navbar {
    display: flex;
    padding: 0rem 1.25rem;
    justify-content: space-around;
    list-style-type: none;
    color: var(--general-light, #FFF);
    font-family: Montserrat;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    right: 0.5rem;
    bottom: 1rem;
}


.policy-link {
    color: white!important;
    text-decoration: none;
}


@media screen and (max-width: 768px) {
    .landing-image {
        content: url('https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/homepage%2FMusic-learning-platform-mobile.webp?alt=media&token=39eb9a0d-56b8-411b-b537-eba565552ebc');
        /* Mobile image */
        height: 40rem;
    }
}

@media only screen and (max-width: 1024px) {
    .items {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.5rem;
    }


    .main-heading {
        margin: 3rem 0rem 0rem 0rem;
        top: 5rem;
        left: 1.5rem;
        width: 90%;
        height: 35rem;
    }

    .headline {
        font-size: 2rem;
    }

    .headline-detail {
        font-size: 1rem;
    }

    .headline-buttons {
        flex-direction: column;
        gap: 1rem;
        width: 75%;
        position: relative;
    }

    .get-started {
        width: 100%;
        justify-content: center;
    }

    .get-started-text {
        font-size: 1rem;
    }

    .trusted-by {
        margin-top: 1rem;
        text-align: center;
        font-size: 0.75rem;
    }

    .badges {
        align-items: center;
        gap: 1rem;
        position: absolute;
        bottom: 1rem;
        flex-wrap: wrap;
    }

    .explore {
        font-size: 1.5rem;
        margin: 1.5rem;
    }

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tutor-images {
        order: 1;
        width: 100%
    }

    .tutor-text {
        order: 2;
        text-align: center;
        padding: 1.5rem !important;
        width: 100%
    }

    .our-tutors {
        font-size: 1.75rem;
        text-align: center;
        margin: 1rem;
    }


    .view-tutors {
        width: 100%;
        justify-content: center;
        margin: 1.5rem 0;
    }

    .top-features {
        padding: 3rem 2rem;
        height: auto;
        /* Allow the section height to adjust based on content */
    }

    .feature-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 0 2rem 0;
        /* Adjust margin for spacing */
    }

    .feature-heading {
        font-size: 3rem;
        width: 100%;
    }

    .feature-text {
        font-size: 1rem;
        width: 100%;
        margin: 1rem 0;
    }

    .get-started-feature {
        font-size: 1rem;
        width: 100%;
        height: 3rem;
    }

    .features-container {
        margin: 1rem 0;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .category-card {
        text-align: center;
    }

    .feature-title {
        font-size: 1.25rem;
    }

    .feature-about {
        font-size: 1rem;
    }

    .top-courses {
        padding: 2rem;
        height: 100%;
    }

    .course-headline {
        font-size: 1.75rem;
    }

    .course-title {
        font-size: 1rem;
    }

    .courses {
        width: 100%;
        max-width: 15rem;
        /* Set a max width to limit stretching */
        margin: 1rem 0;
    }

    .benefits {
        padding: 2rem;
        flex-direction: column;
    }

    .benefit-section {
        width: 100%;
    }

    .benefit-image {
        margin-bottom: 2rem;
    }

    .benefit-heading {
        font-size: 2rem;
        margin: 2rem 0;
        width: 100%;
        text-align: center;
    }

    .benefit-list {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        font-size: 1rem;
    }

    .benefit-text {
        font-size: 1rem;
        margin-left: 0.5rem;
    }

    .testimonials {
        padding: 2rem;
        border-radius: 0;
        margin-bottom: 6rem;
        height: 100%;
    }

    .testimonial-heading {
        font-size: 2rem;
        text-align: center;
    }

    .testimonial-title {
        font-size: 1rem;
        margin-bottom: 1rem;
        text-align: center;
        display: block;
    }

    .testimonial-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin-top: 2rem;
    }

    .testimonial-card {
        width: 100%;
        height: 100%;
        max-width: 20rem;
        margin: 0 auto;
    }

    .student-name {
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
    }

    .student-review {
        font-size: 1rem;
    }

    .footer-music {
        padding: 2rem 1rem;
    }

    .footer-container {
        position: static;
        left: 0;
    }

    .col-6 {
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
    }


    .social-icons {
        width: 100%;
        justify-content: center;
    }

    .contact-details {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 1rem 0;
    }


    .contact-us {
        font-size: 1.5rem;
    }

    .fill-form {
        display: none
    }



}