.welcome-tutor {
    display: flex;
    justify-content: space-between;
}

.create-class {
    background: #7F56D9!important;
    border-color: #7F56D9!important;
    width: 15rem;
    height: 3rem;
    padding: 12px 28px 12px 20px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    color: white!important
}



.create-class:hover {
    background: #7F56D9;
    border-color: #7F56D9;
}

.MuiTextField-root {
    min-width: 150px!important;
}

.edit-profile {
    display: flex;
    justify-content: space-between;
    width:100%
}

.plus-sign {
    margin: 0rem 0.5rem 0rem 0rem;
    position: relative;
    bottom: 0.05rem;
}

.tutor-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.dashboard-cards {
    box-shadow: 0px 4px 11px 0px #0000000F;
    border: 1px solid #E2E2E2;
    background: #FFFFFF;
    width: 18rem;
    height: 6.5rem;
    border-radius: 10px;
    margin: 0.5rem;
    padding: 1.25rem;
}

.dashboard-heading {
    color: #7F56D9;
    font-family: Urbanist;
    font-size: 38px;
    font-weight: 700;
    line-height: 2.5rem;
    text-align: left;
}

.total-earnings {
    color: #126D21;
}

.dashboard-total {
    color: #818181;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
}

.select-date {
    position: relative;
    top: 1rem;
    left: 0.5rem;
}

.create-class-label {
    font-family: Inter!important;
    font-size: 12px!important;
    font-weight: 400!important;
    line-height: 12px!important;
    text-align: left!important;
    color: #7F56D9!important;
}

.MuiInputLabel-outlined{
    font-family: Inter!important;
    font-size: 12px!important;
    font-weight: 400!important;
    line-height: 12px!important;
    text-align: left!important;
    color: #7F56D9!important;
}

.MuiListItem-root {
    padding: 0rem !important;
}

.select-students {
    margin:1rem 0rem 0rem 0rem;
}

.student-list {
    max-height: 15rem;
    overflow: scroll;
}

.student-checkbox {
    min-width: 35px!important;
}

.student-items {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #00000099;
}

.cancel-button {
    margin: 0rem 1rem 0rem 0rem; 
}

.save-button {
    font-family: Inter;
    font-size: 15px;
    color: white;
}

.tutor-profile-image {
    text-align: center;
}

.uploadtutor {
    border-radius: 6rem;
    height: 12rem;
    width: 12rem;
}

.profile-rating {
    display: flex;
    justify-content: center;
    position: relative;
    right: 1.25rem;
    top: 0.75rem;
}

.tutor-profile-rating {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #7F56D9;
}

.edit-image {
    position: relative;
    top: 4.5rem;
    right: 3rem;
    cursor: pointer;
}

.batch-limit-label {
    font-family: Inter !important;
    font-size: 9px !important;
    font-weight: 400 !important;
    line-height: 12px !important;
    color: #7F56D9 !important;
    margin: 0rem 0rem 0.5rem 0rem;
}

.batch-limit {
    height: 2.5rem;
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}


@media (max-width: 1024px) {
    .demo-checkbox {
      position: relative;
      top:1rem;
    }

   
}
