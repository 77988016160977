.schedule-heading {
  font-family: Inter;
  font-weight: bold;
}

.tutor-dropdown {
  margin: 0.5rem;
}

.time-slot {
  font-family: Inter;
  font-size: 14px;
  color: #747474;
}

.change-slot {
  margin: 0.75rem;
  font-family: Inter;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #7f56d9;
}

.available-slots {
  font-family: Inter;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}

.chosen-date {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}

.slots {
  padding: 0.5rem;
}

input[type="radio"] {
  width: 1rem;
  height: 1rem;
}

input[type="radio"]:checked {
  accent-color: #7f56d9 !important;
}

.slot-label {
  margin: 0rem 0.5rem;
  font-family: Inter;
}

/* .Mui-selected {
  background-color: #7F56D9 !important;
  color: white !important;
} */