.tutor-banner {
    height: 15rem !important
}

.tutor-details-banner {
    margin: 2rem;
}

.tutor-stats {
    margin: 1rem 0rem
}

.tutor-details-image {
    width: 15rem;
    height: 15rem;
    border-radius: 1rem;
    margin: 1.5rem 3rem;
    position: relative;
    left: 0.5rem;
}

.courses-by {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #00000099;
    margin: 2rem 2rem 0rem 2rem;
}

.course-by-tutor {
    color: black;
}

.tutor-course-list {
    display: flex;
    justify-content: space-evenly;
    flex-wrap:wrap;
    padding: 2.5rem;
}

.tutor-course-cards {
    width: 26rem;
    height: 8rem;
    border-radius: 16px;
    background-color: white;
    border: 1px solid #EAEAEA;
    display:flex;
    flex-direction: row;
    margin: 0.75rem 0rem;
    cursor: pointer;
}

.tutor-course-image {
    height: 7.9rem;
    width: 12rem;
    border-radius: 0.75rem 0rem 0rem 0.75rem;
}

.course-info-tutor {
    position: relative;
    top: 3.5rem;
}

.course-info-tutor .MuiTabs-flexContainer {
    justify-content: space-evenly !important;
}

/* .Mui-selected {
    color: #7F56D9!important;
} */

.w-100 {
    width: 100%
}


@media screen and (max-width: 1024px) {
    
    .course-title-details {
        font-size: 1.75rem!important;
        font-weight: 500;
    }

    .course-list-tutors{
        width: 100%
    }

    .course-info-tutor{
        width:100%
    }

    .tutor-banner {
        height:75%!important
    }

    .col-8 {
        width: 100%
    }

    .tutor-stats{
        display: flex;
        flex-direction: column;
    }

    .tutor-course-cards {
        width:100%;
    }

    .tutor-details-image {
        height: 15rem;
        border-radius: 1rem;
        margin: 0rem!important;
        position: relative;
        left: 0.5rem;
    }

    .tutor-course-list {
        padding: 1.5rem;
    }

    .courses-by {
        text-align: center
    }
    .course-list-tutors {
        margin: 6rem 0rem 0rem 0rem!important;
    }

    .course-section {
        margin: 0rem 1.25rem;
    }

    .course-info-tutor{
        top: 0rem;
    }
  
}


@media screen and (max-width: 320px) {
    .course-title-details {
        font-size: 1.5rem!important;
        font-weight: 500;
    }
    .course-stats {
        font-size: 0.75rem!important;
    }
}

@media screen and (max-width: 480px) {
    .tutor-course-cards {
        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;
    }
    .tutor-course-list {
        gap: 1rem;
    }

}


